<template class="print-hide">
  <q-header class="bg-grey-10 text-white print-hide">
    <q-toolbar style="height: 80px">
      <!-- <q-btn dense flat round icon="menu" @click="ToggleLeftDrawer" /> -->
      <q-toolbar-title class="lato">SEA In-House Tools</q-toolbar-title>
      <southeastern-logo></southeastern-logo>
    </q-toolbar>
  </q-header>

  <q-drawer
    :width="270"
    bordered
    persistent
    show-if-above
    :breakpoint="0"
    v-model="leftDrawerOpen"
    class="print-hide no-print"
    side="left"
  >
    <q-list>
      <q-item-label header class="text-subtitle1 text-white bg-primary" style="padding: 18px 0 0 18px; height: 55px">
        Main Menu
      </q-item-label>

      <div class="line-h-16 q-mx-md q-my-lg">
        Welcome back <label class="text-capitalize text-weight-medium">{{ UserInfo.userName }}</label>
        <div class="q-mt-md">Today is {{ Sugar.Date(new Date()).medium() }}</div>
      </div>

      <q-separator />

      <MainMenu v-for="link in linksList" :key="link.title" v-bind="link" />

      <div class="line-h-16 text-caption q-mx-md q-mt-md">Your login session will remain open until you select Log Out.</div>
      <div class="line-h-16 text-caption q-mx-md q-my-md">
        Having issues? e-mail IT Support @
        <a class="a2" href="mailto:SE IT <se_it@southeasternaluminum.com>?subject=SEA In-House Tools Help"> SET IT Help</a>
      </div>
    </q-list>
  </q-drawer>
</template>

<script>
import { inject } from "vue";
import store from "@/store";
import Sugar from "sugar";
import MainMenu from "./MainMenu.vue";
import SoutheasternLogo from "./SoutheasternLogo.vue";

const linksList = [
  {
    title: "Work Traveler",
    icon: "mdi-clipboard-list-outline",
    link: "/traveler",
    caption: "generate a work traveler"
  },
  {
    title: "Purchase Orders & XML",
    icon: "mdi-cart-outline",
    link: "/xml",
    caption: "build purchase orders/xml files",
    separator: true
  },
  // {
  //   title: "Table",
  //   icon: "mdi-glass-pint-outline",
  //   link: "/table",
  //   caption: "table for drawings"
  // },
  // {
  //   title: "Slider Test",
  //   icon: "mdi-electric-switch-closed",
  //   link: "/slider",
  //   caption: "slider fraction test",
  //   separator: true
  // },
  {
    title: "Log Out",
    icon: "logout",
    link: "",
    caption: "close this session",
    separator: true
  }
];

export default {
  name: "Header",

  components: { MainMenu, SoutheasternLogo },

  setup() {
    //const leftDrawerOpen = ref(store.state.Depot.LeftDrawer);
    const leftDrawerOpen = inject("leftDrawerOpen");

    return {
      leftDrawerOpen,
      linksList,
      UserInfo: store.state.Depot.UserInfo,
      Sugar,

      ToggleLeftDrawer() {
        //leftDrawerOpen.value = !leftDrawerOpen.value;
        //store.dispatch("Depot/LeftDrawer", leftDrawerOpen.value);
      }
    };
  }
};
</script>
