<template>
  <q-layout view="hHh LpR fff" class="bg-info">
    <MainHeader />

    <q-page-container>
      <router-view />
    </q-page-container>

    <Footer />
  </q-layout>
</template>

<script>
import MainHeader from '../components/MainHeader.vue';
import Footer from '../components/Footer.vue';

export default {
  name: 'MainLayout',

  components: {
    MainHeader,
    Footer
  }
};
</script>
