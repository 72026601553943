<template>
  <div class="print-hide no-print">
    <q-item
      exact
      clickable
      replace
      :to="!isExternal ? link : ''"
      @click="isExternal ? OnClick(link) : icon == 'logout' ? (showLogOut = true) : ''"
      active-class="active-option"
      class="text-black"
    >
      <q-item-section avatar>
        <q-icon :name="icon" />
      </q-item-section>

      <q-item-section>
        <q-item-label>{{ title }}</q-item-label>
        <q-item-label caption class="text-grey-8">
          {{ caption }}
        </q-item-label>
      </q-item-section>
    </q-item>

    <q-separator v-if="separator" />
  </div>

  <q-dialog v-model="showLogOut" persistent style="cursor: default">
    <q-card class="bg-white">
      <div class="q-px-md q-py-xs bg-grey-10 text-white text-subtitle1">Attention!</div>
      <q-card-section class="row items-center">
        <q-avatar icon="question_mark" size="md" color="primary" text-color="white" />
        <span class="q-ml-md">Are you sure you want to Log Out?</span>
      </q-card-section>

      <q-card-actions align="right">
        <q-btn label="Yes" color="primary" flat v-close-popup @click="LogOut" />
        <q-btn label="No" color="primary" flat v-close-popup />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script setup>
import { ref } from "vue";
import store from "@/store";
import { openURL } from "quasar";
import router from "@/router";

name: "MainMenu";

const props = defineProps({
  title: {
    type: String,
    required: true
  },
  caption: {
    type: String,
    default: ""
  },
  link: {
    type: String,
    default: "#"
  },
  icon: {
    type: String,
    default: ""
  },
  separator: {
    type: Boolean,
    default: false
  },
  isExternal: {
    type: Boolean,
    default: false
  }
});

const showLogOut = ref(false);

function LogOut() {
  store.dispatch("Depot/IsLogged", false);
  store.dispatch("Depot/UserInfo", null);

  localStorage.clear();

  router.replace({ path: "/" });
}

function OnClick(link) {
  openURL(link);
}
</script>

<style scoped lang="scss">
@import "@/styles/quasar.variables";

.active-option {
  color: #d1340c !important;
  cursor: default !important;
  background-color: rgb(228, 228, 228) !important;
}
</style>
